import { ShoppingItem } from "./types";

export type ShoppingListAction = {
    type: 'togglePurchased',
    id: number,
} | {
    type: 'addItem',
    itemText: string,
} | {
    type: 'deleteItem',
    id: number,
} | {
    type: 'deleteAllItem',
}



export const shoppingListReducer = (state: ShoppingItem[], action: ShoppingListAction) => {
    switch (action.type) {
        case 'togglePurchased': {
            const updatedShoppingList = state.map((shoppingItem) =>
                shoppingItem.id === action.id ? { ...shoppingItem, purchased: !shoppingItem.purchased } : shoppingItem
            );
            return updatedShoppingList;
        }
        case 'addItem': {
            let maxId = state.reduce((maxId, item) => {
                return item.id > maxId ? item.id : maxId;
            }, 0);
            const newShoppingItem: ShoppingItem = { id: ++maxId, text: action.itemText, purchased: false };
            const updatedShoppingList = [...state, newShoppingItem];
            return updatedShoppingList;
        }
        case 'deleteItem': {
            return state.filter((item) => item.id !== action.id);
        }
        case 'deleteAllItem': {
            return []; 
        }
    }
};

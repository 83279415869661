
import './App.css';

import { TiShoppingCart } from "react-icons/ti";
import SocialMediaLinks from './components/SocialMediaLinks';
import { createContext, Dispatch, useEffect, useReducer, useState } from 'react';
import { ShoppingListAction, shoppingListReducer } from './reducers';
import { ShoppingItem } from './types';
import { MdOutlineDeleteForever } from 'react-icons/md';
import AddItemPanel from './components/AddItemPanel';
import ShoppingListView from './components/ShoppingListView';

export const AppContext = createContext<{ shoppingListState: ShoppingItem[], editable: boolean; }>({ shoppingListState: [], editable: false });
export const ShoppingListDispatchContext = createContext<Dispatch<ShoppingListAction>>(() => undefined);



function getShoppinglist() {
    const jsonData = localStorage.getItem('shoppingList');

    if (jsonData) {
        return JSON.parse(jsonData) as ShoppingItem[];
    }
    return [];
}

export default function App() {

    const [shoppingListState, dispatch] = useReducer(shoppingListReducer, getShoppinglist());
    const [editable, setEditable] = useState(true);

    useEffect(() => {
        localStorage.setItem('shoppingList', JSON.stringify(shoppingListState));
    }, [shoppingListState]);

    return (
        <>

            <div className='xl:flex xl:justify-center'>
                {/* Main */}
                <AppContext.Provider value={{ shoppingListState: shoppingListState, editable }}>
                    <ShoppingListDispatchContext.Provider value={dispatch}>
                        <div className='px-4 xl:w-96'>

                            {/* Header */}
                            <div className='fixed top-0 flex flex-col w-full gap-3 pb-2 bg-white'>

                                {/* Header Title */}
                                <div className='flex items-center pt-2 space-x-2 text-sky-600 '>
                                    <TiShoppingCart className='text-3xl drop-shadow-2xl' />
                                    <h1 className='text-3xl italic drop-shadow-2xl'>Online Shopping List</h1>
                                </div>

                                <SocialMediaLinks />

                                {/* Edit Mode & Delete All  */}
                                <div className="flex items-center">
                                    <label>
                                        <input
                                            checked={editable}
                                            onChange={(e) => setEditable(e.target.checked)}
                                            type="checkbox"
                                            aria-label="Checkbox to switch to edit mode."
                                        /><span> Edit Mode</span>
                                    </label>
                                    {editable && (shoppingListState.length > 1) &&
                                        <button
                                            className="flex items-center px-2 py-1 ml-5 space-x-1 text-sm font-semibold text-white bg-red-600 rounded-xl hover:bg-red-500"
                                            onClick={() => dispatch({ type: 'deleteAllItem' })}
                                        >
                                            <span>Delete All</span>
                                            {/* <MdDeleteForever/> */}
                                            <MdOutlineDeleteForever style={{ fontSize: '18px' }} />
                                        </button>
                                    }
                                </div>

                                {editable &&
                                    <AddItemPanel />
                                }
                            </div>
                            {/* Header End */}



                            <div className='pt-44'>
                                <br />
                                <ShoppingListView />
                                {shoppingListState.length === 0 &&
                                    <p className="italic">This is an online shopping list for mobile devices. You don't need to download or sign up. Your shopping list is saved in your browser. Even if you close your browser, you can access your list when you open it again. </p>
                                }

                            </div>




                        </div>
                    </ShoppingListDispatchContext.Provider>
                </AppContext.Provider>
            </div>

        </>
    );
}


